.App {
  text-align: center;
}

.content-holder{
  margin-left: 65px;
  margin-top: 25px;
  border-radius: 20px;
}
.Container{
  width: 510px;
  background-image: url('https://i.pinimg.com/originals/9f/a5/09/9fa509702fab91b910e82919b5408993.jpg');
  box-shadow: 1rem 1rem 5rem rgba(30, 49, 78, 0.14);
  margin: 3rem auto;
  padding: 3rem 2rem;
  min-height: 18rem;
  border-radius: 14px;
  margin: 0 auto;
}

button{
  border-radius: 80%;
  outline: none;
  padding: .5rem;
  margin-left: .5rem;
  margin-right: .5rem;
}

@media(max-width: 701px){
  .Container{
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;
  }
  
}